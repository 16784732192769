import React, { Component } from 'react';
import bg1 from './img/bg1.jpg';
import bg2 from './img/bg2.jpg';
import bg3 from './img/bg3.jpg';
import bg4 from './img/bg4.jpg';
import sticks from './img/sticks.png';

class Header extends Component {
	render() {
		return(
		   <div class="row mt-2 no-gutters custom-border-bottom">
			<div class='col-lg-2 col-md-3 d-md-block d-none text-center'>
				<img src={bg1} alt='' className='headerImg'/>
			</div>
			<div class='col-lg-1 d-lg-block col-md-1 d-md-block d-none'><img src={sticks} alt='' className='sticksImg'/></div>
			<div class='col-lg-2 col-md-3 d-md-block d-none text-center'>
				<img src={bg2} alt='' className='headerImg'/>
			</div>
			<div class='col-lg-1 d-lg-block col-md-1 d-md-block d-none'><img src={sticks} alt='' className='sticksImg'/></div>
			<div class='col-lg-2 col-md-3 d-md-block d-none text-center'>
				<img src={bg3} alt='' className='headerImg'/>
			</div>
			<div class='col-lg-1 d-lg-block d-md-none d-none'><img src={sticks} alt='' className='sticksImg'/></div>
			<div class='col-lg-2 d-lg-block d-md-none d-none text-center'>
				<img src={bg4} alt='' className='headerImg'/>
			</div>
			<div class='col-lg-1 d-none'></div>
		   </div>
		);
	}
}
export default Header;
