import React, { Component } from 'react';
import Menu from './Menu.js';
import Header from './Header.js';
import chrome from './img/chrome.jpg';

class Chrome extends Component {
	render() {
		return(
			<div class="home">
		 	 <div class="container-fluid">
				<Header/>
				<div class="row custom-margin-top">
				    <Menu url='chrome'/>
				    <main class="col bg-faded py-2 ml-2">
					<div class="large-white-info mt-2">
						<div className='pageHeader'>The Chrome Dome</div>
						<img src={chrome} alt='' className='homeImg'/>
						<div className="mt-4">The Chrome Dome is a standing-room only multi-purpose sports and entertainment venue, owned and operated by Arnold Sports & Entertainment in the hardscrabble town of Lewiston, Maine.  The structure of the building was fabricated with 96% Chromium element (Cr) and 4% concrete.</div>
					</div>
				    </main>
				</div>
			  </div>
			</div>
		);
	}
}
export default Chrome;
