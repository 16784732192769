import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Video from './Video';
import Stats from './Stats';
import Chrome from './Chrome';
import Fructose from './Fructose';
import Champions from './Champions';
import Competitors from './Competitors';
import Contact from './Contact';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { HashRouter, Route, Switch } from 'react-router-dom';

ReactDOM.render(
 <HashRouter>
  <Switch>
   <Route path="/" exact component={App}/>
   <Route path="/video" component={Video}/>
   <Route path="/stats" component={Stats}/>
   <Route path="/chrome" component={Chrome}/>
   <Route path="/fructose" component={Fructose}/>
   <Route path="/competitors" component={Competitors}/>
   <Route path="/champions" component={Champions}/>
   <Route path="/contact" component={Contact}/>
  </Switch>
 </HashRouter>, document.getElementById('root')
);
	
if(module.hot){
	module.hot.accept();
}
