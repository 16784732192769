import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faVideo, faBuilding, faNewspaper, faIdBadge, faTrophy, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom';

class Menu extends Component {
	render() {
		return(
		  <aside class="col-12 col-md-3 p-0 bg-dark">
		    <nav class="navbar navbar-expand navbar-dark bg-dark flex-md-column flex-row align-items-start py-2">
			<div class="navbar navbar-default">
			  <ul class="flex-md-column flex-row navbar-nav w-300 justify-content-between">
        			 <li className={this.props.url === "competitors" ? 'active' : ''}>
			   		<Link class="pl-0 text-nowrap" to='/competitors'><FontAwesomeIcon icon={faIdBadge} /> <span class="d-none d-md-inline">players</span></Link>
			  	 </li>
        		 	<li className={this.props.url === "stats" ? 'active' : ''}>
                     <Link class="pl-0 text-nowrap" to='/stats'><FontAwesomeIcon icon={faChartBar} /> <span class="d-none d-md-inline">stats</span></Link>
				</li>
				<li className={this.props.url === "champions" ? 'active' : ''}>
					<Link class="pl-0 text-nowrap" to='/champions'><FontAwesomeIcon icon={faNewspaper} />  <span class="d-none d-md-inline">champions</span></Link>
				</li>
				<li className={this.props.url === "video" ? 'active' : ''}>
					<Link class="pl-0 text-nowrap" to='/video'><FontAwesomeIcon icon={faVideo} />  <span class="d-none d-md-inline">video</span></Link>
				</li>
				<li className={this.props.url === "chrome" ? 'active' : ''}>
					<Link class="pl-0 text-nowrap" to='/chrome'><FontAwesomeIcon icon={faBuilding} />  <span class="d-none d-md-inline">venue</span></Link>
				</li>
				<li className={this.props.url === "fructose" ? 'active' : ''}>
					<Link class="pl-0 text-nowrap" to='/fructose'><FontAwesomeIcon icon={faTrophy} />  <span class="d-none d-md-inline">fructose</span></Link>
				</li>
				<li className={this.props.url === "contact" ? 'active' : ''}>
					<Link class="pl-0 text-nowrap" to='/contact'><FontAwesomeIcon icon={faEnvelope} />  <span class="d-none d-md-inline">contact</span></Link>
				</li>
                     </ul>	
		  </div>
		 </nav>
		</aside>
		);
	}
}
export default Menu;
