import React, { Component } from 'react';
import Menu from './Menu.js';
import Header from './Header.js';

class Contact extends Component {
	render() {
		return(
			<div class="home">
		 	 <div class="container-fluid">
				<Header/>
				<div class="row custom-margin-top">
				    <Menu url='contact'/>
				    <main class="col bg-faded py-2 ml-4">
					<div class="mt-5 mr-5">
          					<div className='contactInfo'>Contact <a className='contactLink' href="mailto:breakawaytv@yahoo.com">breakawaytv@yahoo.com</a> to learn more and get involved in this great competition!</div>
 					 </div>
				    </main>
				</div>
			  </div>
			</div>
		);
	}
}
export default Contact;
