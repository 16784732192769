import React, { Component } from 'react';
import './App.css';
import {Animated} from "react-animated-css";
import chrome from './img/chrome.jpg';
import Menu from './Menu.js';
import Header from './Header.js';

class App extends Component {
	render() {
		return (
			<div class="home">
		 	 <div class="container-fluid">
				<Header/>
				<div class="row custom-margin-top">
				    <Menu/>
				    <main class="col bg-faded py-1 ml-2">
				
					   <Animated animationIn="lightSpeedIn" animationOut="bounce" isVisible={true}>
						<div className='pageHeader'>Welcome to Breakaway TV</div>
						<img src={chrome} alt='' className='homeImg'/>
					   </Animated>
				    </main>
				</div>
			 </div>
			</div>
		);
	}
}

export default App;
