import React, { Component } from 'react';
import {Animated} from "react-animated-css";
import Menu from './Menu.js';
import Header from './Header.js';
import Profiles from './Profiles.js';

class Competitors extends Component {
	render() {
		let profileKeys = Object.keys(Profiles);
		
		return(
			<div class="home">
		 	 <div class="container-fluid">
				<Header/>
				<div class="row custom-margin-top">
				    <Menu url='competitors'/>
				    <main class="col bg-faded ml-4">
						<Animated animationIn="fadeInDownBig" animationOut="bounce" isVisible={true}>
						  <div class="row">
								{profileKeys.map( (key) => {
									return <div class="col text-nowrap mt-5">
										<div>{Profiles[key].name}</div>
										<div className="font-italic font-weight-light">"{Profiles[key].desc}"</div>
										<img src={Profiles[key].pic} alt='' className='profileImg'/>
									</div>
								})}
						</div>
					   </Animated>
				    </main>
				</div>
			  </div>
			</div>
		);
	}
}
export default Competitors;
