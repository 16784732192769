import React, { Component } from 'react';
import Menu from './Menu.js';
import Header from './Header.js';
import StatData from './StatsData.js';

class Stats extends Component {
	constructor(){
		super();
		this.state = {openStat: 'ppg'};
		this.toggleStat = this.toggleStat.bind(this);
	}

	toggleStat(e){
		this.setState({openStat: e.target.id});
	}

	render() {
		return(
			<div class="home">
		 	 <div class="container-fluid">
				<Header/>
				<div class="row custom-margin-top">
				    <Menu url='stats'/>
				    <main class="col bg-faded py-2 ml-2">
					    <div class="whiteContentSection">
					      {StatData.ppg.map( (category,i) => {
						return <div class="card">
    						 <div className={"card-header" + ((this.state.openStat === category.id) ? " activeStat" : '')}>
	 					   <button onClick={this.toggleStat} id={category.id} className="btn btn-link">{category.label}</button>
    						 </div>
    						 <div className={"collapse" + ((this.state.openStat === category.id) ? " show" : '')}>
						    <div className='setSection' key={i}>
								<div class="row setLabel">
									<div class="col">{category.player}</div>
									<div class="col">Games</div>
									<div class="col">Points</div>
									<div class="col">Avg</div>
								</div>
								{category.stats.map( (stat) => {
									return <div class="row">
									  <div className='col'>{stat.player}</div>
									  <div className='col'>{stat.games}</div>
									  <div className='col'>{stat.points}</div>
									  <div className='col'>{(stat.points / stat.games).toFixed(2)}</div>
									</div>
								})} 
						  </div>
						 </div>
						</div>
					      })}

					      {StatData.winningpct.map( (category,i) => {
						return <div class="card">
    						 <div className={"card-header" + ((this.state.openStat === category.id) ? " activeStat" : '')}>
	 					   <button onClick={this.toggleStat} id={category.id} className="btn btn-link">{category.label}</button>
    						 </div>
    						 <div className={"collapse" + ((this.state.openStat === category.id) ? " show" : '')}>
						    <div className='setSection' key={i}>
								<div class="row setLabel">
									<div class="col">{category.player}</div>
									<div class="col">Wins</div>
									<div class="col">Games</div>
									<div class="col">%</div>
								</div>
								{category.stats.map( (stat) => {
									return <div class="row">
									  <div className='col'>{stat.player}</div>
									  <div className='col'>{stat.wins}</div>
									  <div className='col'>{stat.games}</div>
									  <div className='col'>{((stat.wins / stat.games)*100).toFixed(2)}</div>
									</div>
								})} 
						  </div>
						 </div>
						</div>
					      })}
					      
					      {StatData.shooters.map( (category,i) => {
						return <div class="card">
    						 <div className={"card-header" + ((this.state.openStat === category.id) ? " activeStat" : '')}>
	 					   <button onClick={this.toggleStat} id={category.id} className="btn btn-link">{category.label}</button>
    						 </div>
    						 <div className={"collapse" + ((this.state.openStat === category.id) ? " show" : '')}>
						    <div className='setSection' key={i}>
								<div class="row setLabel">
									<div class="col">{category.player}</div>
									<div class="col">Goals</div>
									<div class="col">Shots</div>
									<div class="col">{(category.id === 'goalie') ? "Save " : ""}%</div>
								</div>
								{category.stats.map( (stat) => {
									return <div className={"row " + ((stat.shots === 0) ? "font-italic" : "")}>
									  <div className='col'>{stat.shooter}</div>
									  <div className='col'>{stat.goals}</div>
									  <div className='col'>{stat.shots}</div>
									  <div className='col'>{(stat.shots !== 0) ? (((category.id === 'goalie') ? (100 - ((stat.goals / stat.shots)*100)).toFixed(2) : (((stat.goals / stat.shots)*100).toFixed(2) ))) : "0.00"}</div>
									</div>
								})}	 
						   </div>
    					    	 </div>
  						</div>
						})}
					   </div>
					</main>
				</div>
			 </div>
			</div>
		);
	}
}
export default Stats;
