const Videos = {
	btv39: {
		id: "vid6", url: "www.youtube.com/embed/so_M9xfg3eE", name: "BTV Highlights #39"
	},
	btv24: {
		id: "vid5", url: "www.youtube.com/embed/sp3Yn4o2Dkk", name: "BTV Highlights #24"
	},
	btv22: {
		id: "vid4", url: "www.youtube.com/embed/tYH-MdHRxX0", name: "BTV Highlights #22"
	},
	btv21: {
		id: "vid3", url: "www.youtube.com/embed/p6LdGYUiR8k", name: "BTV Highlights #21"
	},
	btv20: {
		id: "vid2", url: "www.youtube.com/embed/ajqw-oSbOkQ", name: "BTV Highlights #20"
	},
	btv19: {
		id: "vid1", url: "www.youtube.com/embed/aY6TILWY9Lo", name: "BTV Highlights #19"
	}
}
export default Videos;
