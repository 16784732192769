import patPic from './img/profiles/pat.jpg';
import philPic from './img/profiles/phil.jpg';
import scottPic from './img/profiles/scott.jpg';
import joshPic from './img/profiles/josh.jpg';
import nickPic from './img/profiles/nick.jpg';
import mikePic from './img/profiles/mike.jpg';
import jimPic from './img/profiles/jim.jpg';
import danPic from './img/profiles/dan.jpg';
import markPic from './img/profiles/mark.jpg';
import bradPic from './img/profiles/brad.jpg';

const Profiles = {
	pat: {
		pic: patPic, name: "Pat", desc: "Mr. Breakaway TV"
	},
	phil: {
		pic: philPic, name: "Phil", desc: "BTV's heart & soul" 
	},
	josh: {
		pic: joshPic, name: "Josh", desc: "My Microphone" 
	},
	mark: {
		pic: markPic, name: "Mark", desc: "Small Net Maestro" 
	},
	scott: {
		pic: scottPic, name: "Scott", desc: "12 Time Champ" 
	},
	nick: {
		pic: nickPic, name: "Nick", desc: "Fructose Creator" 
	},
	brad: {
		pic: bradPic, name: "Brad", desc: "Top Corner Classic" 
	},
	mike: {
		pic: mikePic, name: "Mike", desc: "Here I Come!" 
	},
	dan: {
		pic: danPic, name: "Dan", desc: "Spot starter" 
	},
	jim: {
		pic: jimPic, name: "Jim", desc: "Mysterious one" 
	},
}

export default Profiles;
