const ChampionList = 
[
	{"num": 1, "winner": "Pat", "title": 1, "score": "Pat-5<br/> Mark-3<br/> Josh-2<br/> Phil-2<br/> Nick-0"},
	{"num": 2, "winner": "Pat", "title": 2, "score": "Pat-5<br/> Mark-4<br/> Phil-2<br/> Nick-1"},
	{"num": 3, "winner": "Pat", "title": 3, "score": "Pat-5<br/> Phil-4<br/> Josh-4<br/> Dan-2"},
	{"num": 4, "winner": "Pat", "title": 4, "score": "Pat-5<br/> Nick-4<br/> Josh-4<br/> Phil-3<br/> Dan-1"},
	{"num": 5, "winner": "Pat", "title": 5, "score": "Pat-5<br/> Mark-4<br/> Nick-4<br/> Phil-2<br/> Josh-1"},
	{"num": 6, "winner": "Mark", "title": 1, "score": "Mark-5<br/> Pat-4<br/> Phil-3<br/> Dan-1"},
	{"num": 7, "winner": "Phil", "title": 1, "score": "Phil-5<br/> Mark-4<br/> Dan-3<br/> Nick-1<br/> Pat-1<br/> Josh-0"},
	{"num": 8, "winner": "Pat", "title": 6, "score": "Pat-5<br/> Phil-4<br/> Josh-3<br/> Nick-3<br/> Mark-1<br/> Dan-0"},
	{"num": 9, "winner": "Phil", "title": 2, "score": "Phil-5<br/> Pat-4<br/> Nick-4<br/> Josh-4"},
	{"num": 10, "winner": "Nick", "title": 1, "score": "Nick-5<br/> Josh-4<br/> Pat-4<br/> Phil-4"},
	{"num": 11, "winner": "Pat", "title": 7, "score": "Pat-5<br/> Phil-2<br/> Nick-2<br/> Josh-2"},
	{"num": 12, "winner": "Pat", "title": 8, "score": "Pat-5<br/> Phil-3<br/> Josh-2<br/> Dan-1"},
	{"num": 13, "winner": "Pat", "title": 9, "score": "Pat-5<br/> Phil-3<br/> Josh-1<br/> Nick-1"},
	{"num": 14, "winner": "Nick", "title": 2, "score": "Nick-5<br/> Pat-4<br/> Josh-4<br/> Phil-4"},
	{"num": 15, "winner": "Phil", "title": 3, "score": "Phil-5<br/> Josh-4<br/> Pat-4<br/> Nick-2"},
	{"num": 16, "winner": "Pat", "title": 10, "score": "Pat-5<br/> Phil-4<br/> Nick-3<br/> Josh-1<br/> Jim-1"},
	{"num": 17, "winner": "Pat", "title": 11, "score": "Pat-5<br/> Phil-4<br/> Nick-3<br/> Jim-2"},
	{"num": 18, "winner": "Pat", "title": 12, "score": "Pat-5<br/> Phil-2<br/> Scott-2<br/> Nick-2<br/> Josh-1"},
	{"num": 19, "winner": "Scott", "title": 1, "score": "Scott-5<br/> Pat-4<br/> Josh-2<br/> Nick-2<br/> Phil-1"},
	{"num": 20, "winner": "Pat", "title": 13, "score": "Pat-5<br/> Scott-4<br/> Josh-3<br/> Jim-2<br/> Nick-1<br/> Phil-1"},
	{"num": 21, "winner": "Pat", "title": 14, "score": "Pat-5<br/> Scott-2<br/> Josh-1<br/> Nick-1<br/> Phil-1"},
	{"num": 22, "winner": "Scott", "title": 2, "score": "Scott-5<br/> Nick-3<br/> Pat-2<br/> Josh-1<br/> Phil-0<br/> Dan-0"},
	{"num": 23, "winner": "Scott", "title": 3, "score": "Scott-5<br/> Phil-4<br/> Brad-4<br/> Pat-4<br/> Josh-3"},
	{"num": 24, "winner": "Scott", "title": 4, "score": "Scott-5<br/> Pat-4<br/> Mike-3<br/> Brad-3<br/> Nick-2<br/> Jim-1<br/> Phil-1"},
	{"num": 25, "winner": "Scott", "title": 5, "score": "Scott-5<br/> Pat-4<br/> Phil-3<br/> Nick-2<br/> Jim-1<br/> Brad-1<br/> Mike-0"},
	{"num": 26, "winner": "Pat", "title": 15, "score": "Pat-5<br/> Brad-2<br/> Josh-2<br/> Scott-2<br/> Phil-1<br/> Nick-0"},
	{"num": 27, "winner": "Pat", "title": 16, "score": "Pat-5<br/> Scott-4<br/> Josh-2<br/> Phil-1<br/> Brad-1"},
	{"num": 28, "winner": "Pat", "title": 17, "score": "Pat-5<br/> Phil-4<br/> Scott-4<br/> Brad-4<br/> Jim-2<br/> Mike-2<br/> Nick-1"},
	{"num": 29, "winner": "Scott", "title": 6, "score": "Scott-5<br/> Nick-3<br/> Phil-3<br/> Pat-2<br/> Mike-1<br/> Jim-1"},
	{"num": 30, "winner": "Scott", "title": 7, "score": "Scott-5<br/> Phil-4<br/> Pat-3<br/> Brad-2<br/> Nick-2<br/> Mike-1"},
	{"num": 31, "winner": "Pat", "title": 18, "score": "Pat-5<br/> Mike-3<br/> Brad-2<br/> Scott-2<br/> Nick-1<br/> Phil-0<br/> Josh-0"},
	{"num": 32, "winner": "Brad", "title": 1, "score": "Brad-5<br/> Scott-4<br/> Phil-3<br/> Pat-2<br/> Mike-1<br/> Josh-0<br/> Nick-0"},
	{"num": 33, "winner": "Mike", "title": 1, "score": "Mike-5<br/> Phil-4<br/> Pat-4<br/> Nick-3<br/> Josh-2"},
	{"num": 34, "winner": "Scott", "title": 8, "score": "Scott-5<br/> Pat-3<br/> Phil-3<br/> Dan-2<br/> Nick-2<br/> Josh-2"},
	{"num": 35, "winner": "Scott", "title": 9, "score": "Scott-5<br/> Phil-3<br/> Brad-1<br/> Nick-1<br/> Pat-1"},
	{"num": 36, "winner": "Phil", "title": 4, "score": "Phil-5<br/> Scott-4<br/> Brad-3<br/> Pat-2<br/> Nick-1<br/> Dan-0"},
	{"num": 37, "winner": "Scott", "title": 10, "score": "Scott-5<br/> Pat-2<br/> Brad-1<br/> Nick-1<br/> Phil-0<br/> Dan-0"},
	{"num": 38, "winner": "Scott", "title": 11, "score": "Scott-5<br/> Brad-4<br/> Phil-3<br/> Josh-2<br/> Pat-2<br/> Nick-1"},
	{"num": 39, "winner": "Scott", "title": 12, "score": "Scott-5<br/> Pat-4<br/> Phil-3<br/> Josh-3<br/> Nick-3"},
	{"num": 40, "winner": "Pat", "title": 19, "score": "Pat-5<br/> Scott-4<br/> Phil-4<br/> Josh-1"},
	{"num": 41, "winner": "Phil", "title": 5, "score": "Phil-5<br/> Scott-3<br/> Dan-3<br/> Pat-1<br/> Josh-1"},
	{"num": 42, "winner": "TBD", "title": '', "score": "TBD"}
];
export default ChampionList;
