const ShooterData = 
[ 
	{"id" : "shooter", 
	 "label": "All Shooters",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Scott", "goals": 526, "shots": 1308},
		{"shooter": "Pat", "goals": 776, "shots": 2359},
		{"shooter": "Brad", "goals": 233, "shots": 828},
		{"shooter": "Phil", "goals": 665, "shots": 2487},
		{"shooter": "Jim", "goals": 98, "shots": 470},
		{"shooter": "Mike", "goals": 111, "shots": 536},
		{"shooter": "Nick", "goals": 402, "shots": 2030},
		{"shooter": "Josh", "goals": 333, "shots": 1766},
		{"shooter": "Mark", "goals": 54, "shots": 289},
		{"shooter": "Dan", "goals": 82, "shots": 590}
	  ]
	},
	{"id" : "goalie", 
	 "label": "All Goalies",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Mark", "goals": 63, "shots": 446},
		{"shooter": "Pat", "goals": 565, "shots": 3012},
		{"shooter": "Dan", "goals": 107, "shots":440},
		{"shooter": "Phil", "goals": 586, "shots": 2328},
		{"shooter": "Nick", "goals": 387, "shots": 1521},
		{"shooter": "Scott", "goals": 506, "shots": 1980},
		{"shooter": "Josh", "goals": 428, "shots": 1260},
		{"shooter": "Jim", "goals": 102, "shots": 299},
		{"shooter": "Brad", "goals": 309, "shots": 842},
		{"shooter": "Mike", "goals": 227, "shots": 535}
	  ]
	},
	{"id" : "pat", 
	 "label": "Pat as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Scott", "goals": 127, "shots":392},
		{"shooter": "Brad", "goals": 59, "shots": 232},
		{"shooter": "Mark", "goals": 23, "shots": 111},
		{"shooter": "Phil", "goals": 134, "shots": 752},
		{"shooter": "Nick", "goals": 98, "shots": 571},
		{"shooter": "Josh", "goals": 84, "shots": 551},
		{"shooter": "Mike", "goals": 16, "shots": 122},
		{"shooter": "Jim", "goals": 11, "shots": 125},
		{"shooter": "Dan", "goals": 13, "shots": 156}
	  ]
	},
	{"id" : "patshooter", 
	 "label": "Pat as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Mike", "goals": 61, "shots": 110},
		{"shooter": "Jim", "goals": 31, "shots": 65},
		{"shooter": "Josh", "goals": 146, "shots": 367},
		{"shooter": "Scott", "goals": 165, "shots": 447},
		{"shooter": "Brad", "goals": 55, "shots": 167},
		{"shooter": "Phil", "goals": 167, "shots":599},
		{"shooter": "Nick", "goals": 99, "shots": 371},
		{"shooter": "Dan", "goals": 27, "shots": 110},
		{"shooter": "Mark", "goals": 25, "shots": 123},
	  ]
	},
	{"id" : "mark", 
	 "label": "Mark as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Pat", "goals": 25, "shots": 123},
		{"shooter": "Phil", "goals": 17, "shots": 121},
		{"shooter": "Dan", "goals": 6, "shots": 54},
		{"shooter": "Josh", "goals": 7, "shots": 69},
		{"shooter": "Nick", "goals": 8, "shots": 79},
		{"shooter": "Jim", "goals": 0, "shots": 0},
		{"shooter": "Scott", "goals": 0, "shots": 0},
		{"shooter": "Brad", "goals": 0, "shots": 0},
		{"shooter": "Mike", "goals": 0, "shots": 0}
	  ]
	},
	{"id" : "markshooter", 
	 "label": "Mark as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Josh", "goals": 5, "shots": 19},
		{"shooter": "Dan", "goals": 8, "shots": 32},
		{"shooter": "Pat", "goals": 23, "shots": 111},
		{"shooter": "Nick", "goals": 11, "shots": 57},
		{"shooter": "Phil", "goals": 7, "shots": 70},
		{"shooter": "Jim", "goals": 0, "shots": 0},
		{"shooter": "Scott", "goals": 0, "shots": 0},
		{"shooter": "Brad", "goals": 0, "shots": 0},
		{"shooter": "Mike", "goals": 0, "shots": 0}
	  ]
	},

	{"id" : "josh", 
	 "label": "Josh as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Jim", "goals": 11, "shots": 21},
		{"shooter": "Scott", "goals": 59, "shots": 132},
		{"shooter": "Pat", "goals": 146, "shots": 367},
		{"shooter": "Brad", "goals": 16, "shots": 45},
		{"shooter": "Phil", "goals": 104, "shots": 336},
		{"shooter": "Dan", "goals": 21, "shots": 78},
		{"shooter": "Mark", "goals": 5, "shots": 19},
		{"shooter": "Nick", "goals": 64, "shots": 254},
		{"shooter": "Mike", "goals": 2, "shots": 8}
	  ]
	},
	{"id" : "joshshooter", 
	 "label": "Josh as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Brad", "goals": 37, "shots": 87},
		{"shooter": "Jim", "goals": 6, "shots": 21},
		{"shooter": "Phil", "goals": 84, "shots": 401},
		{"shooter": "Nick", "goals": 55, "shots": 267},
		{"shooter": "Mike", "goals": 9, "shots": 44},
		{"shooter": "Scott", "goals": 40, "shots": 234},
		{"shooter": "Pat", "goals": 84, "shots": 551},
		{"shooter": "Dan", "goals": 11, "shots": 92},
		{"shooter": "Mark", "goals": 7, "shots": 69}
	  ]
	},

	{"id" : "phil", 
	 "label": "Phil as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Scott", "goals": 128, "shots": 297},
		{"shooter": "Brad", "goals": 56, "shots": 164},
		{"shooter": "Pat", "goals": 167, "shots":599},
		{"shooter": "Mike", "goals": 22, "shots": 99},
		{"shooter": "Josh", "goals": 84, "shots": 401},
		{"shooter": "Nick", "goals": 86, "shots": 466},
		{"shooter": "Jim", "goals": 15, "shots": 96},
		{"shooter": "Dan", "goals": 21, "shots": 136},
		{"shooter": "Mark", "goals": 7, "shots": 70}
	  ]
	},
	{"id" : "philshooter", 
	 "label": "Phil as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Mike", "goals": 52, "shots":106},
		{"shooter": "Brad", "goals": 77, "shots": 173},
		{"shooter": "Jim", "goals": 29, "shots": 70},
		{"shooter": "Dan", "goals": 33, "shots": 106},
		{"shooter": "Josh", "goals": 104, "shots": 336},
		{"shooter": "Scott", "goals": 116, "shots":418},
		{"shooter": "Nick", "goals": 103, "shots": 405},
		{"shooter": "Pat", "goals": 134, "shots": 752},
		{"shooter": "Mark", "goals": 17, "shots": 121}
	  ]
	},
	{"id" : "nick", 
	 "label": "Nick as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Scott", "goals": 53, "shots": 143},
		{"shooter": "Mike", "goals": 29, "shots": 89},
		{"shooter": "Pat", "goals": 99, "shots": 371},
		{"shooter": "Phil", "goals": 103, "shots": 405},
		{"shooter": "Brad", "goals": 13, "shots": 53},
		{"shooter": "Jim", "goals": 14, "shots": 64},
		{"shooter": "Josh", "goals": 55, "shots": 267},
		{"shooter": "Mark", "goals": 11, "shots": 57},
		{"shooter": "Dan", "goals": 10, "shots": 72}
	  ]
	},
	{"id" : "nickshooter", 
	 "label": "Nick as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Mike", "goals": 32, "shots": 98},
		{"shooter": "Josh", "goals": 64, "shots": 254},
		{"shooter": "Jim", "goals": 13, "shots": 57},
		{"shooter": "Brad", "goals": 27, "shots": 124},
		{"shooter": "Scott", "goals": 68, "shots": 328},
		{"shooter": "Phil", "goals": 86, "shots": 466},
		{"shooter": "Pat", "goals": 98, "shots": 571},
		{"shooter": "Dan", "goals": 6, "shots": 53},
		{"shooter": "Mark", "goals": 8, "shots": 79},
	  ]
	},
	{"id" : "dan", 
	 "label": "Dan as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Brad", "goals": 4, "shots": 5},
		{"shooter": "Scott", "goals": 18, "shots": 42},
		{"shooter": "Phil", "goals": 33, "shots": 106},
		{"shooter": "Mark", "goals": 8, "shots": 32},
		{"shooter": "Pat", "goals": 27, "shots": 110},
		{"shooter": "Josh", "goals": 11, "shots": 92},
		{"shooter": "Nick", "goals": 6, "shots": 53},
		{"shooter": "Jim", "goals": 0, "shots": 0},
		{"shooter": "Mike", "goals": 0, "shots": 0}
	  ]
	},
	{"id" : "danshooter", 
	 "label": "Dan as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Josh", "goals": 21, "shots": 78},
		{"shooter": "Mark", "goals": 8, "shots": 32},
		{"shooter": "Phil", "goals": 21, "shots": 136},
		{"shooter": "Nick", "goals": 10, "shots": 72},
		{"shooter": "Scott", "goals": 11, "shots": 85},
		{"shooter": "Pat", "goals": 13, "shots": 156},
		{"shooter": "Brad", "goals": 0, "shots": 9},
		{"shooter": "Jim", "goals": 0, "shots": 0},
		{"shooter": "Mike", "goals": 0, "shots": 0}
		]
	},
	{"id" : "jim", 
	 "label": "Jim as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Pat", "goals": 31, "shots": 65},
		{"shooter": "Phil", "goals": 29, "shots": 70},
		{"shooter": "Scott", "goals": 12, "shots":39},
		{"shooter": "Josh", "goals": 6, "shots": 21},
		{"shooter": "Mike", "goals": 7, "shots": 26},
		{"shooter": "Nick", "goals": 13, "shots": 57},
		{"shooter": "Brad", "goals": 4, "shots": 21},
		{"shooter": "Mark", "goals": 0, "shots": 0},
		{"shooter": "Dan", "goals": 0, "shots": 0}
	  ]
	},
	{"id" : "jimshooter", 
	 "label": "Jim as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Josh", "goals": 11, "shots": 21},
		{"shooter": "Brad", "goals": 16, "shots":37},
		{"shooter": "Mike", "goals": 14, "shots": 40},
		{"shooter": "Nick", "goals": 14, "shots": 64},
		{"shooter": "Scott", "goals": 17, "shots": 87},
		{"shooter": "Phil", "goals": 15, "shots": 96},
		{"shooter": "Pat", "goals": 11, "shots": 125},
		{"shooter": "Dan", "goals": 0, "shots": 0},
		{"shooter": "Mark", "goals": 0, "shots": 0},
		]
	},

	{"id" : "scott", 
	 "label": "Scott as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Pat", "goals": 165, "shots": 447},
		{"shooter": "Phil", "goals": 116, "shots":418},
		{"shooter": "Brad", "goals": 64, "shots": 250},
		{"shooter": "Nick", "goals": 68, "shots": 328},
		{"shooter": "Jim", "goals": 17, "shots": 87},
		{"shooter": "Mike", "goals": 25, "shots": 131},
		{"shooter": "Josh", "goals": 40, "shots": 234},
		{"shooter": "Dan", "goals": 11, "shots": 85},
		{"shooter": "Mark", "goals": 0, "shots": 0}
	  ]
	},
	{"id" : "scottshooter", 
	 "label": "Scott as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Mike", "goals": 42, "shots": 79},
		{"shooter": "Brad", "goals": 87, "shots": 184},
		{"shooter": "Josh", "goals": 59, "shots": 132},
		{"shooter": "Phil", "goals": 128, "shots": 297},
		{"shooter": "Dan", "goals": 18, "shots": 42},
		{"shooter": "Nick", "goals": 53, "shots": 143},
		{"shooter": "Pat", "goals": 127, "shots":392},
		{"shooter": "Jim", "goals": 12, "shots":39},
		{"shooter": "Mark", "goals": 0, "shots": 0},
		]
	},

	{"id" : "brad", 
	 "label": "Brad as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Scott", "goals": 87, "shots": 184},
		{"shooter": "Phil", "goals": 77, "shots": 173},
		{"shooter": "Jim", "goals": 16, "shots":37},
		{"shooter": "Josh", "goals": 37, "shots": 87},
		{"shooter": "Pat", "goals": 55, "shots": 167},
		{"shooter": "Nick", "goals": 27, "shots": 124},
		{"shooter": "Mike", "goals": 10, "shots": 61},
		{"shooter": "Dan", "goals": 0, "shots": 9},
		{"shooter": "Mark", "goals": 0, "shots": 0}
	  ]
	},
	{"id" : "bradshooter", 
	 "label": "Brad as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Dan", "goals": 4, "shots": 5},
		{"shooter": "Josh", "goals": 16, "shots": 45},
		{"shooter": "Phil", "goals": 56, "shots": 164},
		{"shooter": "Mike", "goals": 17, "shots": 58},
		{"shooter": "Scott", "goals": 64, "shots": 250},
		{"shooter": "Nick", "goals": 13, "shots": 53},
		{"shooter": "Pat", "goals": 59, "shots": 232},
		{"shooter": "Jim", "goals": 4, "shots": 21},
		{"shooter": "Mark", "goals": 0, "shots": 0},
		]
	},

	{"id" : "mike", 
	 "label": "Mike as Goalie",
	 "player": "Shooter",
	 "stats": [ 
		{"shooter": "Pat", "goals": 61, "shots": 110},
		{"shooter": "Scott", "goals": 42, "shots": 79},
		{"shooter": "Phil", "goals": 52, "shots":106},
		{"shooter": "Jim", "goals": 14, "shots": 40},
		{"shooter": "Nick", "goals": 32, "shots": 98},
		{"shooter": "Brad", "goals": 17, "shots": 58},
		{"shooter": "Josh", "goals": 9, "shots": 44},
		{"shooter": "Mark", "goals": 0, "shots": 0},
		{"shooter": "Dan", "goals": 0, "shots": 0}
	  ]
	},
	{"id" : "mikeshooter", 
	 "label": "Mike as Shooter",
	 "player": "Goalie",
	 "stats": [ 
		{"shooter": "Nick", "goals": 29, "shots": 89},
		{"shooter": "Jim", "goals": 7, "shots": 26},
		{"shooter": "Josh", "goals": 2, "shots": 8},
		{"shooter": "Phil", "goals": 22, "shots": 99},
		{"shooter": "Scott", "goals": 25, "shots": 131},
		{"shooter": "Brad", "goals": 10, "shots": 61},
		{"shooter": "Pat", "goals": 16, "shots": 122},
		{"shooter": "Dan", "goals": 0, "shots": 0},
		{"shooter": "Mark", "goals": 0, "shots": 0},
		]
	}
];
const PointsPerData = 
[
	{"id" : "ppg", 
	 "label": "Points Per Game Average",
	 "player": "Player",
	 "stats": [ 
		{"player": "Scott", "games": 23, "points": 95},
		{"player": "Pat", "games": 41, "points": 160},
		{"player": "Mark", "games": 6, "points": 21},
		{"player": "Phil", "games": 41, "points": 118},
		{"player": "Brad", "games": 13, "points": 33},
		{"player": "Josh", "games": 30, "points": 62},
		{"player": "Nick", "games": 34, "points": 70},
		{"player": "Mike", "games": 8, "points": 16},
		{"player": "Jim", "games": 7, "points": 10},
		{"player": "Dan", "games": 11, "points": 13}
	]}
];

const WinningPctData = 
[
	{"id" : "wining", 
	 "label": "Winning Percentage",
	 "player": "Player",
	 "stats": [ 
		{"player": "Scott", "games": 23, "wins": 12},
		{"player": "Pat", "games": 41, "wins": 19},
		{"player": "Mark", "games": 6, "wins": 1},
		{"player": "Mike", "games": 8, "wins": 1},
		{"player": "Phil", "games": 41, "wins": 5},
		{"player": "Brad", "games": 13, "wins": 1},
		{"player": "Nick", "games": 34, "wins": 2},
		{"player": "Jim", "games": 7, "wins": 0},
		{"player": "Dan", "games": 11, "wins": 0},
		{"player": "Josh", "games": 30, "wins": 0}
	]}
];

const StatsData = {
	shooters : ShooterData,
	ppg : PointsPerData,
	winningpct: WinningPctData
}

export default StatsData;
