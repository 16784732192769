import React, { Component } from 'react';
import Menu from './Menu.js';
import Header from './Header.js';
import YouTubeEmbedder from './YouTubeEmbedder.js';
import Videos from './Videos.js';

class Video extends Component {
	constructor(props){
		super(props);
		this.state = {selectedVideo: Videos.btv39.id};
		this.toggleVideo = this.toggleVideo.bind(this);
	}

	toggleVideo(e){
		this.setState({selectedVideo: e.target.id});
	}

	render() {
		let videoKeys = Object.keys(Videos);
		return(
			<div class="home">
		 	 <div class="container-fluid">
				<Header/>
				<div class="row custom-margin-top">
				    <Menu url='video'/>
				    <main class="col bg-faded py-2 ml-2">
					    <div class="videoSection">
						{videoKeys.map( (key) => {
							return 	<YouTubeEmbedder 
								show_video={this.state.selectedVideo === Videos[key].id}
								video_id={Videos[key].id}
								video_url={Videos[key].url}/>
						   })}
						{videoKeys.map( (key) => {
							return <div className="text-center pt-2">
								 <button onClick={this.toggleVideo} id={Videos[key].id} className="btn btn-secondary">
									{Videos[key].name}
								 </button>
								</div>
						})}		
					   </div>
				      </main>
				</div>
			 </div>
			</div>
		);
	}
}
export default Video;
