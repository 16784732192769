import React, { Component } from 'react';
import Menu from './Menu.js';
import Header from './Header.js';
import {Link} from 'react-router-dom';
import fructose1 from './img/fructose1.jpg';
import fructose2 from './img/fructose2.jpg';

class Fructose extends Component {
	render() {
		return(
			<div class="home">
		 	 <div class="container-fluid">
				<Header/>
				<div class="row custom-margin-top">
				    <Menu url='fructose'/>
				    <main class="col bg-faded py-2 ml-4">
					<div class="large-white-info mt-4">
						<div className='pageHeader'>Fructose Award</div>
						<div className="d-inline"><img src={fructose1} alt='' className='fructoseImg'/></div>
						<div className="d-inline ml-3"><img src={fructose2} alt='' className='fructoseWideImg'/></div>
						<div className="mt-4 p-4">The Fructose Award is given to the winner of our competition. It was created by competitor Nick.  The award is presently at home with Phil, the current champion.</div>
 					 </div>
					<Link class="pl-0 text-nowrap" to='/champions'><button type="button" class="btn btn-primary">History of Champions</button> </Link>
				    </main>
				</div>
			  </div>
			</div>
		);
	}
}
export default Fructose;
