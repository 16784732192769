import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Menu from './Menu.js';
import Header from './Header.js';
import ChampionList from './ChampionList.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt, faTrophy } from '@fortawesome/free-solid-svg-icons'

class Champions extends Component {
	render() {
		return(
			<div class="home">
		 	 <div class="container-fluid">
				<Header/>
				<div class="row custom-margin-top">
				    <Menu url='champions'/>
				    <main class="col bg-faded py-0 ml-2">
					<ReactTooltip place="left" type="dark" effect="float" multiline="true" />
					 <table class="table table-striped table-bordered">
		 				  <thead class="thead-dark">
						    <tr>
   						     <th scope="col">BTV #</th>
    						     <th scope="col">Winner</th>
      						     <th scope="col">Title#</th>
      						     <th scope="col">Score</th>
    						    </tr>
  						   </thead>
  						  <tbody>
							{ChampionList.map( (champ) => {
								return <tr className='table-light text-dark'>
									<td>{champ.num}</td>
									<td>{champ.winner}</td>
									<td>{champ.title} <FontAwesomeIcon icon={faTrophy}/></td>
									<td><span data-tip={champ.score}> <FontAwesomeIcon icon={faListAlt}/> </span>
									</td>
								</tr>
							})}
						  </tbody>
						</table>
					</main>
				</div>
			  </div>
			</div>
		);
	}
}
export default Champions;
