import React, {Component} from 'react';

class YouTubeEmbedder extends Component {
	render(){
		return(
			this.props.show_video ?
				<iframe title={this.props.video_url} width={350} height={350} src={"//" + this.props.video_url} frameborder="0" allowfullscreen></iframe>
				: ""
		);
	}
}
export default YouTubeEmbedder;
